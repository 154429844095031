import { Button } from '@careos/react-ui/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '@careos/react-ui/DropdownMenu';

import type { HeaderProps } from './header';
import { LanguageSelector } from './language-selector';
import { LogOut } from './log-out';
import { UserEmail } from './user-email';

export const UserMenu = ({ onLogout, user }: HeaderProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="ml-auto overflow-hidden rounded-full"
        >
          {user.name?.charAt(0)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <LanguageSelector />
        <LogOut onLogout={onLogout} />
        <DropdownMenuSeparator />
        <UserEmail email={user.email} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
