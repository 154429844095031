import {
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuCheckboxItem,
} from '@careos/react-ui/DropdownMenu';
import { Languages } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import i18n, { supportedLanguages } from '@/i18n';
import { changeLanguage } from '@/utils/change-language';

export const LanguageSelector = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'user_menu' });

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <Languages className="mr-2 size-4" />
        {t('language')}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {supportedLanguages.map((lang) => (
            <DropdownMenuCheckboxItem
              key={lang}
              checked={i18n.language === lang}
              onCheckedChange={() => changeLanguage(lang)}
            >
              {t(lang)}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
