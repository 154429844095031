/// <reference types="vite/client" />

import * as Sentry from '@sentry/react';
import type { BrowserOptions } from '@sentry/react';

type SentryOptions = Omit<BrowserOptions, 'router'>;

/**
 * Initialize Sentry with the given router and options.
 * Will use the environment and DSN from the import.meta.env if not provided in options.
 * Preferably have VITE_SENTRY_DSN set in your .env file.
 * @param router tanstackRouter instance
 * @param options
 */
export function initializeSentry(router: unknown, options: SentryOptions = {}) {
  const { dsn: optionsDsn, environment: optionsEnvironment, ...rest } = options;
  const isProd = import.meta.env.PROD;
  const dsn = optionsDsn ?? import.meta.env.VITE_SENTRY_DSN ?? '';
  const environment = optionsEnvironment ?? import.meta.env.NODE_ENV ?? '';

  if (!dsn) {
    console.warn('No Sentry DSN provided, skipping Sentry initialization');
    return;
  }

  Sentry.init({
    dsn: dsn ?? import.meta.env.VITE_SENTRY_DSN,
    environment: environment ?? import.meta.env.NODE_ENV,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
    ],
    replaysSessionSampleRate: isProd ? 0.1 : 0,
    replaysOnErrorSampleRate: isProd ? 1.0 : 0,
    ...rest,
  });
}
