import * as Sentry from '@sentry/react';
import type { ErrorBoundaryProps, FallbackRender } from '@sentry/react';
import { ErrorFallback } from './ErrorFallback';

/**
 * ErrorBoundary component that catches errors and displays a generic fallback UI.
 * Enhances Sentry errors with component stack traces when used.
 * Provide your own fallback component to customize the UI.
 */
export const ErrorBoundary = ({
  children,
  fallback,
  ...rest
}: ErrorBoundaryProps) => {
  const defaultFallback: FallbackRender = (props) => (
    <ErrorFallback {...props} />
  );

  return (
    <Sentry.ErrorBoundary fallback={fallback || defaultFallback} {...rest}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
