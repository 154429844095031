import type { User } from '@auth0/auth0-react';
import { DropdownMenuItem } from '@careos/react-ui/DropdownMenu';
import { Mail } from 'lucide-react';

type Props = {
  email: User['email'];
};

export const UserEmail = ({ email }: Props) => {
  return (
    <DropdownMenuItem disabled>
      <Mail className="mr-2 size-4" />
      {email}
    </DropdownMenuItem>
  );
};
