import { type User } from '@auth0/auth0-react';

import { AbcLogo } from './abc-logo';
import { UserMenu } from './user-menu';

export type HeaderProps = {
  onLogout: () => void;
  user: User;
};

export const Header = ({ user, onLogout }: HeaderProps) => {
  return (
    <header className="sticky top-0 z-50 w-full border-b-2 border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 max-w-screen-xl items-center">
        <AbcLogo />
        <div className="ml-auto flex items-center gap-x-2">
          <span className=" text-slate-500">
            {user?.organization?.display_name}
          </span>
          <UserMenu user={user} onLogout={onLogout} />
        </div>
      </div>
    </header>
  );
};
