import { DropdownMenuItem } from '@careos/react-ui/DropdownMenu';
import { LogOut as LogOutIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type Props = {
  onLogout: () => void;
};

export const LogOut = ({ onLogout }: Props) => {
  const { t } = useTranslation();
  return (
    <DropdownMenuItem onClick={onLogout}>
      <LogOutIcon className="mr-2 size-4" />
      {t('user_menu.log_out')}
    </DropdownMenuItem>
  );
};
