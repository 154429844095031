import { useTranslation } from 'react-i18next';

export function NotFoundComponent() {
  const { t } = useTranslation('translation');
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <img
        src="/abclabs.svg"
        alt="abclogo"
        className="m-6 h-36 rounded-md p-4"
      />
      <p className="text-4xl">{t('nav.not_found')}</p>
    </div>
  );
}
